<!--
 * @Description: 人员信息窗口
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-05-28 14:42:28
 * @LastEditors: yanxiao
-->
<template>
  <div class="main">
    <div class="close"
         @click="close">x</div>
    <div class="row1">
      <el-image style="margin: 0 10px"
                :src="img"></el-image>
      <div>
        <div>巡检员：{{data.userName}}</div>
        <div>
          <span style="margin-right:20px;">编号：{{data.code}}</span>状态：
          <span v-if="data.status===0"
                style="color: #0B8841;">工作中</span>
          <span v-else-if="data.status===1"
                style="color: #F8E71C;">休息中</span>
        </div>
      </div>
    </div>
    <div class="row2">
      <div class="row2-left">
        <div class="data-item">
          <span>轨迹开始时间：</span>
          <el-date-picker v-model="start"
                          style="width: 220px;"
                          size="mini"
                          type="datetime"
                          placeholder="轨迹开始时间"
                          @change="startChange">
          </el-date-picker>
        </div>
        <div class="data-item">
          <span>轨迹开始时间：</span>
          <el-date-picker v-model="end"
                          style="width: 220px;"
                          size="mini"
                          type="datetime"
                          placeholder="轨迹结束时间"
                          @change="endChange">
          </el-date-picker>
        </div>
      </div>
      <div class="row2-right">
        <div class="but"
             @click="butClick">轨迹回放</div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    id: {
      type: String,
      default: 'person'
    },
    // 摄像头编号
    data: {
      type: Object,
      default: () => {
        return {
          userName: '巡检员',
          code: 'RY_01'
        }
      }
    }
  },
  data () {
    //这里存放数据
    return {
      img: require('@/assets/dataBoards/person.png'),
      start: null,
      end: null
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    butClick () {
      if (this.start && this.end) {
        this.$emit('butClick', this.start, this.end)
      }
    },
    startChange (val) {
      if (this.end && val.getTime() > this.end.getTime()) {
        this.start = null
        this.end = val
      }
    },
    endChange (val) {
      if (this.start && val.getTime() < this.start.getTime()) {
        this.end = null
        this.start = val
      }
    },
    close () {
      this.$emit("close")
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.main {
  width: 450px;
  height: 130px;
  border-radius: 10px;
  border: 1px solid #2b678a;
  background: #01325ff5;
  overflow: hidden;
  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 20px;
    height: 15px;
    text-align: center;
    line-height: 10px;
    cursor: pointer;
  }
  .row1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 12px;
    line-height: 20px;
    .img {
      margin: 0 10px;
    }
  }
  .row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    .row2-left {
      width: 75%;
      height: 70px;
      .data-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        padding-left: 10px;
      }
    }
    .row2-right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 70px;
      .but {
        display: inline-block;
        padding: 3px 10px;
        font-size: 10px;
        background: #029ee2;
        border-radius: 15px;
        cursor: pointer;
        &:active {
          background: #23aae4;
        }
      }
    }
  }
}
/deep/.el-input__prefix {
  top: 5px;
}
/deep/.el-input__suffix {
  top: 5px;
}
</style>