<!--
 * @Description: 人行道停车运营管理 数据看板 资源全景 parkDataBorads
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="body">
    <div class="map-container">
      <div id="map"></div>
    </div>
    <!-- 指南针 -->
    <div class="zhinanzheng"
         @click="handleClickZhinan">
      <svg width="80px"
           height="80px"
           viewBox="0 0 48 48"
           version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>icon</title>
        <g id="图标"
           stroke="none"
           stroke-width="1"
           fill="none"
           fill-rule="evenodd">
          <g id="路内停车管理备份"
             transform="translate(-4.000000, -4.000000)">
            <g id="icon"
               transform="translate(5.000000, 5.000000)">
              <path d="M23,0 C10.2987429,0 0,10.2987429 0,23 C0,35.7065143 10.2987429,46 23,46 C35.7012571,46 46,35.7065143 46,23 C46,10.2987429 35.7012571,0 23,0"
                    id="Fill-1"
                    stroke="#090707"
                    fill="#272727"></path>
              <g id="编组-3"
                 transform="translate(22.309637, 2.449167)"
                 stroke="#494B48">
                <line x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="4"
                      id="路径-2"></line>
                <line x1="0.5"
                      y1="37.5508333"
                      x2="0.5"
                      y2="41.5508333"
                      id="路径-2备份"></line>
              </g>
              <g id="编组-3备份"
                 transform="translate(22.809637, 23.449167) rotate(-270.000000) translate(-22.809637, -23.449167) translate(22.309637, 2.449167)"
                 stroke="#494B48">
                <line x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="4"
                      id="路径-2"></line>
                <line x1="0.5"
                      y1="37.5508333"
                      x2="0.5"
                      y2="41.5508333"
                      id="路径-2备份"></line>
              </g>
              <g id="编组"
                 transform="translate(19.000000, 13.000000)">
                <polygon id="Fill-1"
                         fill="#F84D4D"
                         points="0.00703255814 10.3442093 0.000195348837 10.3632558 3.8983814 20.7172326 7.7692186 10.3510465 7.76677674 10.3442093"></polygon>
                <polygon id="Fill-2"
                         fill="#FFF8F5"
                         points="3.86863953 0.000195348837 0.00708139535 10.3444047 7.76682558 10.3444047 3.86912791 0.000195348837"></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="left-select">
      <div>
        <el-select v-model="area"
                   placeholder="请选择">
          <el-option v-for="item in areaList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="jiedao"
                   placeholder="请选择"
                   clearable=""
                   @change="jiedaoChange">
          <el-option v-for="item in jiedaoList"
                     :key="item.code"
                     :label="item.regionName"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="right-select">
      <div class="right-item"
           id="parkBut"
           :style="showPark? colorJson[theme].select: colorJson[theme].normal"
           @click="parkClick">
        <el-image :src="colorJson[theme].park"></el-image>
        <span>停车场</span>
      </div>
      <div class="right-item"
           id="personBut"
           :style="showPerson? colorJson[theme].select: colorJson[theme].normal"
           @click="personClick">
        <el-image :src="colorJson[theme].renyuan"></el-image>
        <span>人员</span>
      </div>
      <el-popover placement="bottom"
                  trigger="click">
        <div class="right-pop">
          <el-tree :data="rightTreeData"
                   show-checkbox
                   @check-change="handleCheckChange">
            <div class="tree-item"
                 slot-scope="{ node, data }">
              <el-image style="width: 13px; height: 13px; margin-right: 5px"
                        :src="theme==='dark'? data.img :data.img1"></el-image>
              <span>{{ node.label }}</span>
            </div>
          </el-tree>
        </div>
        <div id="equipmentBut"
             slot="reference"
             class="right-item"
             :style="colorJson[theme].normal">
          <el-image :src="colorJson[theme].dev"></el-image>
          <span>设备</span>
        </div>
      </el-popover>
    </div>
    <el-dialog :title="popTitle"
               :visible.sync="showParkDetail"
               append-to-body
               :modal="false"
               destroy-on-close
               width="50%"
               style="margin-left:10%">
      <div class="parkDetails-box">
        <div class="row1">
          <div v-for="item in titleList"
               :key="item.key">
            <div :style="colorJson[theme].color">{{ item.label }}</div>
            <div>{{ item.count }} </div>
          </div>
        </div>
        <div class="parkDetails-watch">
          <div class="parkDetails-watch-content">
            <div style="display:flex;margin-top:20px">
              <div style="line-height:30px;margin-left:50px"
                   :style="colorJson[theme].gaowei">选择监控类型</div>
              <div class="parkDetails-watch-content-select"
                   style="margin-left:10px">
                <el-select v-model="selectType"
                           placeholder="请选择"
                           style="width:200px"
                           @change='changeCameraType'>
                  <el-option v-for="item in cameraTypeList"
                             :key="item.code"
                             :label="item.name"
                             :value="item.code">
                  </el-option>
                </el-select>
              </div>
              <div style="line-height:30px;margin-left:10px"
                   :style="colorJson[theme].gaowei">选择监控相机</div>
              <div class="parkDetails-watch-content-select"
                   style="margin-left:10px">
                <el-select v-model="selectVideo"
                           placeholder="请选择"
                           style="width:200px">
                  <el-option v-for="item in videoList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
                <div class="btn"
                     @click="getVideo">实时播放</div>
              </div>
            </div>
            <video controls
                   muted
                   id="videoElementOne"></video>
          </div>
        </div>
      </div>
    </el-dialog>
    <div v-show="false">
      <!-- 停车场信息窗口 -->
      <parkInfoWindow ref="parkingInfoWindow"
                      :id="id"
                      :data="data"
                      @close="infoWindowClose"
                      @detailsClick="detailsClick"></parkInfoWindow>
      <!-- 巡检员信息窗口 -->
      <personInfoWindow ref="personInfoWindow"
                        :id="id"
                        :data="data"
                        @butClick="playback"></personInfoWindow>
      <!-- 摄像头信息窗口 -->
      <monitorInfoWindow ref="monitorInfoWindow"
                         :id="id"
                         :data="data"
                         :status="data.status"
                         @close="infoWindowClose"
                         @detailsClick="detailsClick"></monitorInfoWindow>
      <monitorInfoWindow ref="monitorInfoWindow"
                         :id="id"
                         :data="data"
                         :status="data.status"
                         @close="infoWindowClose"
                         @detailsClick="monitorDetailsClick"></monitorInfoWindow>
    </div>
    <el-dialog :title="popTitle1"
               :visible.sync="showMonitorDetail"
               append-to-body
               :modal="false"
               destroy-on-close>
      <monitorDetails :data="data"
                      @cbShow='showDetails'></monitorDetails>
    </el-dialog>
    <div style="position: absolute; top: 50%; left: 50%"></div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
import Amap from "@/common/js/map.js";
import flvjs from "flv.js";
import parkInfoWindow from "./components/parkInfoWindow";
import personInfoWindow from "./components/personInfoWindow";
import monitorInfoWindow from "./components/monitorInfoWindow";
import monitorDetails from "./components/monitorDetails";

export default {
  components: {
    parkInfoWindow,
    personInfoWindow,
    monitorInfoWindow,
    monitorDetails
  },
  data () {
    //这里存放数据
    return {
      colorJson: {
        dark: {
          select: 'background:#137fa1;border: none',
          normal: 'background:#143c6d;border: none',
          gaowei: "",
          color: "color:white",
          park: require('@/assets/dataBoards/park.png'),
          renyuan: require('@/assets/dataBoards/renyuanguanli.png'),
          dev: require('@/assets/dataBoards/charge.png')
        },
        light: {
          select: 'background:#F5F7FA;border:none',
          normal: 'background:#fff;border: none',
          gaowei: "color:black",
          color: "color:black",
          park: require('@/assets/parkIcon/park.png'),
          renyuan: require('@/assets/parkIcon/gasStation.png'),
          dev: require('@/assets/parkIcon/charge.png')
        }
      },
      map: null,
      area: "渝北区",
      areaList: [
        {
          value: "渝北区",
          label: "渝北区",
        },
      ],
      jiedao: "", // 街道
      jiedaoList: [],
      jiedaoObj: [],
      // 右侧弹出框树形组件数据
      rightTreeData: [
        {
          label: "道闸",
          value: "barrierGate",
          img: require("@/assets/dataBoards/barrierGate.png"),
          img1: require("@/assets/parkIcon/gateLight.png"),
        },
        {
          label: "诱导屏",
          value: "screen",
          img: require("@/assets/onlineMonitor/guideScreen.png"),
          img1: require("@/assets/parkIcon/screenLight.png"),
          children: [
            {
              label: "一级诱导屏",
              value: "screen",
              img: require("@/assets/onlineMonitor/guideScreen.png"),
              img1: require("@/assets/parkIcon/screenLight.png"),
            },
            {
              label: "二级诱导屏",
              value: "screen",
              img: require("@/assets/onlineMonitor/guideScreen.png"),
              img1: require("@/assets/parkIcon/screenLight.png"),
            },
            {
              label: "三级诱导屏",
              value: "thirdScreen",
              img: require("@/assets/onlineMonitor/guideScreen.png"),
              img1: require("@/assets/parkIcon/screenLight.png"),
            },
          ],
        },
        {
          label: "卡口监控",
          value: "bayonet",
          img: require("@/assets/dataBoards/monitor.png"),
          img1: require("@/assets/parkIcon/cameraLight.png"),
        },
        {
          label: "环境监控",
          value: "environment",
          img: require("@/assets/dataBoards/monitor.png"),
          img1: require("@/assets/parkIcon/cameraLight.png"),
        },
        {
          label: "卡口立柱",
          value: "bayonetPillar",
          img: require("@/assets/dataBoards/phone.png"),
          img1: require("@/assets/parkIcon/phoneLight.png"),
        },
      ],
      parkList: [],  // 停车场列表
      // 人员假数据
      personList: [
        {
          longitude: 106.60415,
          latitude: 29.710469,
          userName: "1号巡检员",
          code: "RY-01-00001",
          status: 0,
        }
      ],
      barrierGateList: [], // 道闸列表
      bayonetList: [],
      environmentList: [],
      firstScreenList: [],
      thirdScreenList: [],
      bayonetPillarList: [],

      monitorList: [],// 摄像头列表
      showMonitorDetail: false,
      showPark: false,// 停车场是否选中
      showPerson: false, // 人员是否选中
      showParkDetail: false,// 是否展示停车场详情弹窗
      id: "", // 信息窗口绑定变量
      data: {},
      popTitle: "", // 停车场详情弹窗标题
      popTitle1: "",
      flvUrlOne: "",
      videoList: [], // 摄像头列表
      selectVideo: "",  //选中的摄像头值
      selectType: "",  //选中的摄像头类型值
      cameraTypeList: [], //摄像头类型列表
      infoList: {}, //信息列表
      titleList: [
        {
          label: "总车位数",
          key: "parkSpaceNum",
          count: "0",
        },
        {
          label: "剩余车位数",
          key: "leftSpaceNumber",
          count: "0",
        },
        {
          label: "今日停车车次",
          key: "parkTimes",
          count: "0",
        },
        {
          label: "今日产生订单数",
          key: "orderNumber",
          count: "0",
        },
        {
          label: "已完成订单数",
          key: "orderCompleteNumber",
          count: "0",
        },
        {
          label: "已收金额（元）",
          key: "totalReceivedMoneyAmount",
          count: "0",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  props: {
    // data: {
    //   type: Object,
    // },
  },
  //监控data中的数据变化
  watch: {
    theme () {
      this.initMap();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getRegion();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initMap();
    this.parkClick();
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 初始化地图
    initMap () {
      this.map = new Amap(
        "map",
        [106.562865, 29.600376],
        // 点位所需的图标
        {
          monitor: { mapIco: require("@/assets/dataBoards/monitorOnline.png") },
          parking: { mapIco: require("@/assets/dataBoards/pointParking.png") },
          person: { mapIco: require("@/assets/dataBoards/pointPerson.png") },
          barrierGate: { mapIco: require("@/assets/onlineMonitor/barrierGateOnline.png") },
          bayonet: { mapIco: require("@/assets/dataBoards/monitorOnline.png") },
          environment: { mapIco: require("@/assets/dataBoards/monitorOnline.png") },
          screen: { mapIco: require("@/assets/onlineMonitor/guideScreenOnline.png") },
          bayonetPillar: { mapIco: require("@/assets/dataBoards/monitorOnline.png") },
        },
        // 信息窗口所需的dom
        {
          parking: this.$refs.parkingInfoWindow.$el,
          person: this.$refs.personInfoWindow.$el,
          barrierGate: this.$refs.monitorInfoWindow.$el,
          screen: this.$refs.monitorInfoWindow.$el,
          bayonet: this.$refs.monitorInfoWindow.$el,
          environment: this.$refs.monitorInfoWindow.$el,
          bayonetPillar: this.$refs.monitorInfoWindow.$el,
        },
      );
      // 点击点标记的回调
      this.map.clickMark = (id, data) => {
        this.data = {};
        console.log(data);
        this.$dataBoards.getOrderOnlineNoPage({ parkId: data.parkId }).then(() => {
          this.$onroadParkRoadNext.baseInfo({ parkId: data.parkId }).then(res => {
            data.leftSpaceNumber = res.resultEntity.parkSpaceNumber - res.resultEntity.occupiedNumber
            this.id = id;
            this.data = data;
            this.init()
          })
        }).catch(() => {
          this.id = id;
          this.data = data;
        });
      };
    },
    // 初始化
    init () {
      Promise.all([this.getMenu(), this.getIncome(this.data.parkId)]).then(resList => {
        let params = {};
        for (let i = 0; i < resList.length; i++) {
          const item = resList[i];
          params = { ...this.data, ...this.infoList, ...item, };
        }
        this.titleList.forEach((item) => {
          item.count = params[item.key] || "0";
        });
      });
    },
    // 获取停车场信息
    getMenu () {
      return new Promise((r) => {
        this.$onroadParkRoadNext.baseInfo({ parkId: this.data.parkId }).then((res) => {
          this.data.leftSpaceNumber = res.resultEntity.parkSpaceNumber - res.resultEntity.occupiedNumber
          this.infoList = res.resultEntity;
          if (this.infoList.turnoverRate)
            this.infoList.turnoverRate = Number(this.infoList.turnoverRate).toFixed(2);
          if (this.infoList.usageRate)
            this.infoList.usageRate = Number(this.infoList.usageRate).toFixed(2);
          this.$forceUpdate();
          r(res.resultEntity);
        });
      });
    },
    // 获取停车场收入
    getIncome (parkId) {
      return new Promise((r) => {
        this.$dataBoards.getIncome({ parkId }).then((res) => {
          r(res.resultEntity);
        });
      });
    },
    // 获取街道
    getRegion () {
      this.$dataBoards.queryRegion({}).then((res) => {
        this.jiedaoList = res.resultEntity;
        res.resultEntity.forEach((item) => {
          this.jiedaoObj[item.code] = item;
        });
      });
    },
    // 街道发生改变
    jiedaoChange (val) {
      let jiedao = this.jiedaoObj[val];
      // 如果停车场是激活状态，则更新停车场点坐标
      if (this.showPark) {
        this.map.removeMarker("parking", this.parkList);
        this.getYard(() => {
          this.map.setMarker("parking", this.parkList);
        });
      }
      if (val) {
        this.map.setCenter(jiedao.longitude, jiedao.latitude);
        this.map.setZoom(17);
      } else {
        this.map.setCenter(106.562865, 29.600376);
        this.map.setZoom(17);
      }
    },
    // 获取停车场
    getYard (callback) {
      const loading = this.$loading({
        target: "#parkBut",
        lock: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      let info = {
        parkTypeCode: "2",
        areaCode: this.jiedao,
        pageNum: 1,
        pageSize: 10000,
      };
      this.$yardManagementApi.ParkController(info).then((response) => {
        this.parkList = response.resultEntity.list;
        loading.close();
        if (callback) {
          callback();
        }
      }).catch(() => {
        loading.close();
      });
    },
    // 点击停车场
    parkClick () {
      this.showPark = !this.showPark;
      if (this.showPark) {
        this.getYard(() => {
          this.map.setMarker("parking", this.parkList);
        });
      } else {
        this.map.removeMarker("parking", this.parkList);
        this.map.closeInfoWindow();
      }
    },
    // 设备选择
    handleCheckChange (node, a) {
      console.log(node, a);
      if (node.value === "barrierGate") {
        if (a) {
          this.getBarrierGateList(() => {
            this.map.setMarker(node.value, this.barrierGateList);
          });
        } else {
          this.map.removeMarker(node.value, this.barrierGateList);
          this.map.closeInfoWindow();
        }
      } else if (node.value === "thirdScreen") {
        if (a) {
          this.getFirstScreenList(() => {
            this.map.setMarker("screen", this.thirdScreenList);
          });
        } else {
          this.map.removeMarker("screen", this.thirdScreenList);
          this.map.closeInfoWindow();
        }
      } else if (node.value === "bayonet") {
        if (a) {
          this.getBayonetList(() => {
            this.map.setMarker(node.value, this.bayonetList);
          });
        } else {
          this.map.removeMarker(node.value, this.bayonetList);
          this.map.closeInfoWindow();
        }
      } else if (node.value === "environment") {
        if (a) {
          this.getEnvironmentList(() => {
            this.map.setMarker(node.value, this.environmentList);
          });
        } else {
          this.map.removeMarker(node.value, this.environmentList);
          this.map.closeInfoWindow();
        }
      } else if (node.value === "bayonetPillar") {
        if (a) {
          this.getBayonetPillarList(() => {
            this.map.setMarker("bayonetPillar", this.bayonetPillarList);
          });
        } else {
          this.map.removeMarker("bayonetPillar", this.bayonetPillarList);
          this.map.closeInfoWindow();
        }
      }
    },
    // 获取道闸
    getBarrierGateList (callback) {
      const loading = this.$loading({
        target: "#equipmentBut",
        lock: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      let info = {
        pageNum: 1,
        pageSize: 10000,
      };
      this.$barrierGate.queryPassagewayGateList(info).then((res) => {
        res.resultEntity.list.forEach((item) => {
          item.deviceTypeName = "道闸";
          item.type = "barrierGate";
          item.deviceTypeCode = 'passageway_gate'
          item.status = "1";
        });
        this.barrierGateList = res.resultEntity.list;
        loading.close();
        if (callback) {
          callback();
        }
      }).catch(() => {
        loading.close();
      });
    },
    // 获取环境监控
    getEnvironmentList (callback) {
      const loading = this.$loading({
        target: '#equipmentBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      let info = {
        pageNum: 1,
        pageSize: 10000,
        videoTypeList: ["3", "4"],
      }
      this.$posDev.queryVideoList(info).then((res) => {
        res.resultEntity.list.forEach(item => {
          item.deviceTypeName = "环境监控"
          item.type = 'environment'
          item.deviceTypeCode = 'video'
          item.status = "1";
        })
        this.environmentList = res.resultEntity.list
        loading.close()
        if (callback) {
          callback()
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 获取卡口监控
    getBayonetList (callback) {
      const loading = this.$loading({
        target: '#equipmentBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      let info = {
        pageNum: 1,
        pageSize: 10000,
        videoTypeList: ["1", "2"],
      }
      this.$posDev.queryVideoList(info).then((res) => {
        res.resultEntity.list.forEach(item => {
          item.deviceTypeName = "卡口监控"
          item.type = 'bayonet'
          item.deviceTypeCode = 'video'
          // item.status = "1";
        })
        this.bayonetList = res.resultEntity.list
        loading.close()
        if (callback) {
          callback()
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 获取立柱
    getBayonetPillarList (callback) {
      const loading = this.$loading({
        target: '#equipmentBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      let info = {
        pageNum: 1,
        pageSize: 10000,
      }
      this.$bayonetPillar.queryViewScreenList(info).then((res) => {
        res.resultEntity.list.forEach(item => {
          item.deviceTypeName = "卡口立柱"
          item.type = 'bayonetPillar'
          item.deviceTypeCode = 'bayonetPillar'
          // item.status = "1";
        })
        this.bayonetPillarList = res.resultEntity.list
        loading.close()
        if (callback) {
          callback()
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 获取诱导屏类型
    getGuidingScreenTypeList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '37E3D16AA06045859552A5B4AEF4429D',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        response.resultEntity.forEach(item => {
          item.label = item.name
          item.value = item.code
        })
        this.rightTreeData = response.resultEntity
      })
    },
    // 获取诱导屏信息
    getFirstScreenList (callback) {
      const loading = this.$loading({
        target: '#equipmentBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      let info = {
        pageNum: 1,
        pageSize: 10000
      }
      this.$inductionScreen.queryGuidingScreenList(info).then(res => {
        res.resultEntity.list.forEach(item => {
          item.deviceTypeName = "诱导屏"
          item.type = 'screen'
          item.deviceTypeCode = 'guiding_screen'
        })
        this.firstScreenList = res.resultEntity.list
        this.thirdScreenList = res.resultEntity.list
        loading.close()
        if (callback) {
          callback()
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 点击信息窗口详情按钮
    detailsClick (id, data) {
      if (id === "parking") {
        this.popTitle = `露天停车场（${data.parkName}）`;
        this.id = id;
        this.data = data;
        this.showParkDetail = true;
        this.getDevice();
        this.queryCameraTypeList()
      }
    },
    // 点击信息窗口详情按钮
    monitorDetailsClick (id, data) {
      this.data = data
      this.showMonitorDetail = true
      this.popTitle1 = `设备详情`
    },
    //设备详情展示
    showDetails (value) {
      this.showMonitorDetail = value
    },
    // 点击信息窗口关闭按钮
    infoWindowClose () {
      this.map.closeInfoWindow();
    },
    // 获取高位视频列表 
    getDevice () {
      let params = {
        pageNum: 1,
        pageSize: 10000,
        address: this.data.address,
        lotTopCameraType: this.selectType
      };
      this.$cameraDev.queryLotTopCameraList(params).then((res) => {
        let videoList = [];
        res.resultEntity.list.forEach((item) => {
          videoList.push({ ...item, value: item.deviceId, label: item.deviceName, });
        });
        this.videoList = videoList;
        this.selectVideo = videoList[0] ? videoList[0].value : "";
      });
    },
    // 获取高位视频
    getTopCameraList (callback) {
      const loading = this.$loading({
        target: "#equipmentBut",
        lock: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      let info = {
        pageNum: 1,
        pageSize: 10000,
      };
      this.$cameraDev.queryLotTopCameraList(info).then((res) => {
        this.monitorList = res.resultEntity.list;
        loading.close();
        if (callback) {
          callback();
        }
      }).catch(() => {
        loading.close();
      });
    },
    // 获取高位相机类型
    queryCameraTypeList () {
      let info = {
        columnName: ["value", "name"],
        tableName: "tb_dict",
        whereStr: [
          {
            colName: "parent_id",
            value: "77AB242E82C847D99B263D7DC628BA24",
          },
        ]
      };
      this.$queryDict.queryDict(info).then((res) => {
        this.cameraTypeList = res.resultEntity;
        this.selectType = res.resultEntity[0].code
        this.getDevice()
      });
    },
    // 视频类型切换
    changeCameraType () {
      this.getDevice()
    },
    // 获取视频 ⬇⬇⬇
    getVideo () {
      this.$realTimeVideoMonitor.getVideoUrl({}).then((res) => {
        this.flvUrlOne = res;
        if (flvjs.isSupported()) {
          var videoElementOne = document.getElementById("videoElementOne");
          var flvPlayer = flvjs.createPlayer({
            type: "flv",
            url: this.flvUrlOne,
          });
          flvPlayer.attachMediaElement(videoElementOne);
          flvPlayer.load();
          flvPlayer.play();
        }
      });
    },
    // 点击人员
    personClick () {
      this.showPerson = !this.showPerson;
      if (this.showPerson) {
        this.map.setMarker("person", this.personList);
      } else {
        this.map.removeMarker("person", this.personList);
      }
    },
    // 人员信息窗口点击回放
    playback (start, end) {
      console.log("轨迹回放", start, end);
    },
    // 点击指南针
    handleClickZhinan () {
      this.map.setCenter(106.562865, 29.600376);
    },
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.body {
  position: relative;
  width: 100%;
  height: 100%;
}
.map-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  #map {
    width: 100%;
    height: 100%;
  }
}
.zhinanzheng {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 60px;
  bottom: 60px;
}
.left-select {
  position: absolute;
  left: 20px;
  top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  height: 40px;
  div {
    width: 98%;
  }
}

.right-select {
  position: absolute;
  right: 20px;
  top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 174px;
  .right-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    width: 55px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #0f81a2;
    background: #143c6d;
    font-size: 6px;
    line-height: 12px;
    overflow: hidden;
    .el-image {
      width: 20px;
      height: 20px;
    }

    &:active {
      background: #137fa1;
      border: 1px solid #11e1fa;
    }
    &:focus {
      background: #137fa1;
      border: 1px solid #11e1fa;
    }
  }
  .right-item:hover {
    cursor: pointer;
  }
  .right-pop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 110%;
    height: 110%;
  }
  .tree-item {
    background: green;
  }
}
/deep/.el-loading-spinner {
  margin-top: -10px;
}
/deep/.el-dialog__body {
  padding: 0;
}
.parkDetails-box {
  .row1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 60px;
    color: #ffffff;
    padding: 26px 0;
  }
  .row1 > div {
    width: calc((100% - 12px) / 6);
    text-align: center;
    border-right: 2px solid #053760;
    & > div:last-child {
      color: #00ebfb;
      font-size: 26px;
    }
  }
  .parkDetails-watch {
    color: white;
    width: 100%;
    .parkDetails-watch-content {
      width: 100%;
      padding: 10px;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      height: calc(100% - 120px);
      & > div {
        width: 100%;
      }
      & > div > div {
        padding-bottom: 10px;
      }
      .parkDetails-watch-content-select {
        display: flex;

        .btn {
          cursor: pointer;
          width: 100px;
          background: #2dbedd;
          height: 30px;
          border-radius: 4px;
          text-align: center;
          line-height: 30px;
          margin-left: 20px;
        }
      }
      #videoElementOne {
        margin-top: 20px;
        height: 400px;
        width: 98%;
        // height: 100px;
      }
    }
  }
}
</style>